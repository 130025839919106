
import { inject } from '@/inversify';
import { Component, Prop } from 'vue-property-decorator';

import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import type Day from '@/modules/common/types/day.type';

import CalendarChain from '@/modules/common/components/ui-kit/calendar-chain.vue';
import { ASSESSMENT_COLORS } from '@/modules/common/constants';

@Component({
    extends: CalendarChain,
})
export default class RatesCalendarLine extends CalendarChain {
    @inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Prop({ type: Object })
    private hotelData!: ClusterHotelsRatesModel;

    get calculatedAssestments() {
        if (!this.hotelData.compsetMain) {
            return this.documentFiltersService.days.map(day => ({
                type: ASSESSMENT_TYPES.NO_DATA,
                isPastDay: this.documentFiltersService.isPreviousDay(day),
                colorModificator: ASSESSMENT_COLORS[ASSESSMENT_TYPES.NO_DATA],
                isNA: false,
            }));
        }

        return this.documentFiltersService.days
            .map((day, index) => {
                const type = this.clusterRatesService
                    .getAssessment(day, this.hotelData.hotelId);

                return {
                    type,
                    colorModificator: ASSESSMENT_COLORS[type],
                    isPastDay: this.documentFiltersService.isPreviousDay((index + 1) as Day),
                    isNA: this.notAvailableItems[index],
                    isHaveHoliday: this.hasHolidayEvents(day),
                    isHaveLocalEvents: this.hasLocalEvents(day),
                };
            });
    }

    get notAvailableItems() {
        return this.documentFiltersService.days
            .map((day: Day) => this.clusterRatesService
                .isNa(day, this.hotelData.hotelId));
    }

    checkCell(e: MouseEvent) {
        const isCell = (e.target as HTMLDivElement).className.includes('__cell');

        if (isCell) {
            this.$emit('cellhover', e.target);
        }
    }

    cellOut() {
        this.$emit('cellout');
    }

    cellClick(e: MouseEvent) {
        const isCell = (e.target as HTMLDivElement).className.includes('__cell');

        if (isCell) {
            this.$emit('cellclick', e.target);
        }
    }
}
