
import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomTooltip, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import { COLOR_NAME } from '@/modules/common/constants';

@Component({ components: { CustomTooltip } })
export default class DiLiteDayTooltip extends Vue {
    @Prop({ type: HTMLElement })
    pos!: HTMLElement;

    @Prop({ type: String })
    color!: COLOR_NAME;

    get tooltipDir() {
        return TOOLTIP_DIRECTION.BOTTOM;
    }
}
